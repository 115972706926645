/* eslint-disable import/namespace */
import React, { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
      ? 'https://06276b5b32570dee71b43c9f1ef940dc@o4508223372722176.ingest.de.sentry.io/4508223385436240'
      : '',
  tracesSampleRate: 1,
  enabled:
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging',
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1
});

// Register the Service Worker
// if the browser supports it (all modern browsers do it).
if ('serviceWorker' in navigator) {
  (async () => {
    try {
      await navigator.serviceWorker.register('/sw.js');
    } catch (error) {
      console.error('ServiceWorker registration failed:', error);
    }
  })();
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
